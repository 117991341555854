<template>
  <img alt="Company logo" src="./assets/logo.png">
  <HelloWorld msg="STEYRBACH"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
body {

  background-color: #e4e4d9;

}


@font-face {
  font-family: 'SteyrbachFamily';
  src: url('@/assets/font/CormorantInfant-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SteyrbachFamily';
  src: url('@/assets/font/CormorantUpright-Semi.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

/* Apply the fonts to specific elements */
p, a {
  font-family: 'SteyrbachFamily', sans-serif;
  font-weight: 300;
}

h1, h2, h3, h4 {
  font-family: 'SteyrbachFamily', sans-serif;
  font-weight: 600;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
