<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      This site belongs to Steyrbach Automotive of America LLC.<br>
      We are transportation and entertainment company. 
    </p>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="" target="_blank" rel="noopener">Car Models</a></li>
      <li><a href="" target="_blank" rel="noopener">Spare Parts</a></li>
      <li><a href="" target="_blank" rel="noopener">Community Events</a></li>
      <li><a href="" target="_blank" rel="noopener">Development</a></li>
      <li><a href="" target="_blank" rel="noopener">Company News</a></li>
    </ul>
    <h3>Steyrbach Ecosystem</h3>
    <ul>
      <li><a href="" target="_blank" rel="noopener">Erstarker-Zyklon</a></li>
      <li><a href="" target="_blank" rel="noopener">Bergsteiger Platforme</a></li>
      <li><a href="" target="_blank" rel="noopener">greenz_punkd</a></li>
      <li><a href="" target="_blank" rel="noopener">Steyrberry OS</a></li>
      <li><a href="" target="_blank" rel="noopener">Styria</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 10;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #0f0f0f;
}
</style>
